.button {
  position: relative;
  width: fit-content;
  cursor: pointer;

  svg {
    width: 115px;

    path {
      fill: var(--bookNowBtn);
    }
  }

  &_text {
    position: absolute;
    top: 29px;
    left: 27px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 12px;
    color: var(--bookNotBtnText);
    pointer-events: none;
  }

  @media (min-width: 768px) {
    svg {
      width: initial;
      transition: transform 0.35s ease-in-out;

      &:hover {
        transform: rotate(10deg);
      }
    }

    &_text {
      top: 26px;
      left: 42px;
      font-size: 15px;
    }
  }
}
