.slide_item {
  display: flex;
  flex-direction: column-reverse;
  max-width: 1110px;
  margin: 0 auto;
  padding: 0 12px;
  position: relative;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: calc(100vh - 118px);
    margin-top: -30px;
  }
}

.image {
  &_wrapper {
    position: relative;
    margin: 0 auto;
  }

  &_element {
    max-width: 300px;
    width: 100%;
    height: initial;
  }

  &_info {
    .name {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      font-family: 'Poppins', sans-serif;
      font-weight: 700;
      font-size: 20px;
      color: var(--slideItemName);

      svg {
        font-size: 24px;
        color: #303056;
      }

      &_info {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: var(--slideItemInfo);
        text-align: left;
        display: block;
      }
    }

    .line {
      position: absolute;
      left: -20px;
      bottom: 140px;
      width: 120px;
      height: 1px;
      background: var(--slideItemLine);

      &::after {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: var(--slideItemLine);
        display: block;
        position: absolute;
        top: -2px;
        right: -1px;
      }

      &::before {
        content: '';
        width: 1px;
        height: 105px;
        display: block;
        background: var(--slideItemLine);
        position: absolute;
        left: 0;
      }
    }
  }

  @media (min-width: 768px) {
    &_wrapper {
      flex: 0 0 50%;
      margin: initial;
    }

    &_element {
      max-width: 480px;
    }

    &_info {
      .name {
        justify-content: flex-end;
        padding-right: 70px;
        margin-top: 10px;

        &_info {
          padding-right: 70px;
          text-align: right;
        }
      }

      .line {
        width: 140px;
        bottom: 210px;
        right: 50px;
        left: initial;

        &::before {
          right: 0;
          left: initial;
          height: 170px;
        }

        &::after {
          left: 0;
          right: initial;
        }
      }
    }
  }
}

.info {
  max-width: 470px;
  margin: 24px auto 0 auto;
  position: relative;

  &::after {
    content: '';
    background: url('../../assets/images/element_3.svg');
    background-repeat: no-repeat;
    width: 208px;
    height: 208px;
    position: absolute;
    top: -20px;
    left: -30px;
    opacity: 0.8;
    animation: scaleRing 15s ease-in-out infinite;
  }

  &::before {
    content: '';
    background: url('../../assets/images/element_4.svg');
    background-repeat: no-repeat;
    width: 389px;
    height: 411px;
    position: absolute;
    bottom: 24px;
    right: -10px;
    opacity: 0.3;
    animation: scaleSmall 45s ease-in-out infinite;
  }

  &_title {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: var(--slideItemTitle);
    display: block;
  }

  &_subtitle {
    font-family: 'Poppins', sans-serif;
    font-weight: 900;
    color: var(--slideItemSubtitle);
    display: block;
    font-size: 40px;
    line-height: 35px;
    margin: 12px 0 6px 0;
  }

  &_text {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--slideItemText);
    opacity: 0.8;
  }

  &_crypto {
    display: block;
    margin: 12px 0;

    &_text {
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: var(--slideItemText);
    }

    &_types {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      margin-top: 6px;

      img {
        max-width: 24px;
      }
    }
  }

  &_btns {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 24px;
    margin-bottom: 40px;

    &_track {
      display: flex;
      align-items: center;
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 12px;
      color: var(--slideItemTrack);
      cursor: pointer;

      .icon {
        font-size: 20px;
        color: var(--slideItemTrackIcon);
        margin-left: 12px;
      }
    }
  }

  @media (min-width: 768px) {
    max-width: 450px;
    margin: initial;

    &_title {
      font-size: 17px;
    }

    &_subtitle {
      font-size: 58px;
      line-height: 63px;
      margin: 20px 0 12px 0;
    }

    &_btns {
      margin-top: 50px;
    }
  }
}

@keyframes scaleRing {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scaleSmall {
  0% {
    transform: scale(1) rotate(0);
  }
  50% {
    transform: scale(0.6) rotate(45deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}
