.button {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  width: fit-content;
  cursor: pointer;

  svg path {
    stroke: var(--ghostsBtn);
  }

  &_text {
    position: absolute;
    top: 29px;
    left: 37px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: var(--ghostsBtnText);
  }

  @media (max-height: 902px) {
    display: none;
  }
}
