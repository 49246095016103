.swipe {
  position: absolute;
  right: 24px;
  bottom: 24px;
  width: 75px;
  text-align: center;

  &_icon {
    font-size: 36px;
    color: var(--swiperColor);
  }

  &_text {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: var(--swiperColor);
    display: block;
  }

  @media (max-height: 902px) {
    display: none;
  }
}
