* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: var(--background);

  @media (min-width: 768px) {
    width: 100vw;
    height: 100vh;
  }
}

.swiper {
  margin-top: -51px;
  padding-top: 51px;

  @media (min-width: 768px) {
    margin-top: -118px;
    padding-top: 118px;
  }
}

// Captain Sem
body[data-index='0'] {
  &::after {
    content: '';
    background: url('../../assets/images/blur-effect_1.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -2;
  }

  --background: linear-gradient(
    136.16deg,
    rgba(46, 9, 22, 0.97) 12.91%,
    rgba(32, 10, 43, 0.69) 87.68%
  );

  --menuColor: #ff5b79;

  --swiperColor: #f8d3eb;

  --bookNowBtn: #ff5b79;
  --bookNotBtnText: #ffffff;

  --ghostsBtn: #ff5b79;
  --ghostsBtnText: #ffffff;

  --slideItemName: #ffffff;
  --slideItemInfo: #ffffff;
  --slideItemLine: #ff5b79;
  --slideItemTitle: #ff5b79;
  --slideItemSubtitle: #ffffff;
  --slideItemText: #ffffff;
  --slideItemTrack: #ffffff;
  --slideItemTrackIcon: #ff5b79;
}

// Adino & Grahami
body[data-index='1'] {
  &::after {
    content: '';
    background: url('../../assets/images/blur-effect_2.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -2;
  }

  --background: linear-gradient(
    130.02deg,
    rgba(198, 122, 246, 0.712) 0.66%,
    rgba(106, 79, 182, 0.46) 83.32%
  );

  --menuColor: #303056;

  --swiperColor: #303056;

  --bookNowBtn: #ff5b79;
  --bookNotBtnText: #ffffff;

  --ghostsBtn: #f8d3eb;
  --ghostsBtnText: #f8f8fd;

  --slideItemName: #f8f8fd;
  --slideItemInfo: #f8f8fd;
  --slideItemLine: #f8d3eb;
  --slideItemTitle: #303056;
  --slideItemSubtitle: #303056;
  --slideItemText: #303056;
  --slideItemTrack: #303056;
  --slideItemTrackIcon: #303056;
}

// Reiza
body[data-index='2'] {
  &::after {
    content: '';
    background: url('../../assets/images/blur-effect_3.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -2;
  }

  --background: linear-gradient(
    136.16deg,
    rgba(91, 135, 75, 0.76) 12.91%,
    #0c3720 87.68%
  );

  --menuColor: #f8f8fd;

  --swiperColor: #f8f8fd;

  --bookNowBtn: #a7de93;
  --bookNotBtnText: #303056;

  --ghostsBtn: #a7de93;
  --ghostsBtnText: #ffffff;

  --slideItemName: #ffffff;
  --slideItemInfo: #ffffff;
  --slideItemLine: #a7de93;
  --slideItemTitle: #ffffff;
  --slideItemSubtitle: #ffffff;
  --slideItemText: #ffffff;
  --slideItemTrack: #ffffff;
  --slideItemTrackIcon: #a7de93;
}
